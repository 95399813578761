import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Layout } from 'components';

const StyledPage = styled.div`
  position: relative;
  background: var(--main-color-light);
  .uk-container {
    min-height: 600px;
    img {
      height: 100px;
    }
  }
  h1 {
    font-size: 48px;
  }
  p {
    font-family: 'Sansation Regular', sans-serif;
    font-size: 28px;
    line-height: 36px;
  }
`;

const StyledImage = styled.div`
  position: absolute;
  z-index: 1000;
  bottom: 0;
  left: 35%;
  width: 675px;
`;

const StyledCopy = styled.div`
  z-index: 1001;
  position: absolute;
`;

const BookingPage = ({
  data: {
    prismicBookingPage: {
      data: { heading, cta_text, image },
    },
  },
}) => (
  <Layout showTags={false} chatModuleId="321455" chatOpen={true}>
    <StyledPage>
      <StyledImage>
        <Img fluid={image.localFile.childImageSharp.fluid} />
      </StyledImage>
      <div className="uk-container uk-padding-large">
        <StyledCopy>
          <h1>{heading.text}</h1>
          <p className="uk-width-2-3">{cta_text}</p>
        </StyledCopy>
      </div>
    </StyledPage>
  </Layout>
);

export default BookingPage;

export const query = graphql`
  query {
    prismicBookingPage {
      data {
        heading {
          text
        }
        cta_text
        image {
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 675) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
